@use 'sass:color';
@import '_variables';
@import 'header';
@import 'footer';

:root {
	--phoneSize: #{$phoneSize};
	--corner-radius-small: 0.5em;
	--corner-radius-medium: 1em;
}

:root {
	&.dark-mode {
		--background-color: #{$background-color-dark};
		--dark-color: #{$dark-color-dark};
		--dark-color-90: #{rgba($dark-color-dark,0.1)};
		--dark-color-80: #{rgba($dark-color-dark,0.2)};
		--dark-color-30: #{rgba($dark-color-dark,0.7)};
		--dark-color-20: #{rgba($dark-color-dark,0.8)};
		--dark-color-60: #{rgba($dark-color-dark,0.4)};
		--dark-color-70: #{rgba($dark-color-dark,0.3)};
		--dark-color-40: #{rgba($dark-color-dark,0.6)};
		--text-color: #{$text-color-dark};
		--text-color-10: #{rgba($text-color-dark,0.9)};
		--text-color-20: #{rgba($text-color-dark,0.8)};
		--text-color-80: #{rgba($text-color-dark,0.2)};
		--text-color-40: #{rgba($text-color-dark,0.6)};
		--text-color-inverted: #{$text-color-dark};
		--text-color-inverted-40: #{rgba($text-color-dark,0.6)};
		--text-color-inverted-60: #{rgba($text-color-dark,0.4)};
		--text-color-inverted-90: #{rgba($text-color-dark,0.1)};
		--highlight-color: #{$highlight-color-dark};
		--highlight-color-80: #{rgba($highlight-color-dark,0.2)};
		--tags-color: #{$tags-color-dark};
		--tags-color-20: #{rgba($tags-color-dark,0.8)};
		--tags-color-40: #{rgba($tags-color-dark,0.6)};
		--header-links-color: #{$text-color-dark};
		--title-color: #{$title-color-dark};
		--title-color-30: #{rgba($title-color-dark,0.7)};
		--footer-bg-color: #{$home-stripe-color};
		--category-color: #{$category-color-dark};
		--home-stripe-color: #{$home-stripe-color};
		--lightgallery-color: #{$dark-color-dark};
		--pagefind-ui-background: #{$dark-color-dark};
		--pagefind-ui-text: #{$text-color-dark};
		--pagefind-ui-primary: #{$highlight-color-dark};
		--pagefind-ui-border: #{$dark-color-dark};
		--pagefind-ui-tag: #{$tags-color-dark};
	}

	--background-color: #{$background-color};
	--dark-color: #{$dark-color};
	--dark-color-90: #{rgba($dark-color,0.1)};
	--dark-color-80: #{rgba($dark-color,0.2)};
	--dark-color-30: #{rgba($dark-color,0.7)};
	--dark-color-20: #{rgba($dark-color,0.8)};
	--dark-color-60: #{rgba($dark-color,0.4)};
	--dark-color-70: #{rgba($dark-color,0.3)};
	--dark-color-40: #{rgba($dark-color,0.6)};
	--text-color: #{$text-color};
	--text-color-inverted: #{$background-color};
	--text-color-inverted-40: #{rgba($background-color,0.6)};
	--text-color-inverted-60: #{rgba($background-color,0.4)};
	--text-color-inverted-90: #{rgba($background-color,0.1)};
	--text-color-10: #{rgba($text-color,0.9)};
	--text-color-20: #{rgba($text-color,0.8)};
	--text-color-80: #{rgba($text-color,0.2)};
	--text-color-40: #{rgba($text-color,0.6)};
	--highlight-color: #{$highlight-color};
	--highlight-color-80: #{rgba($highlight-color,0.2)};
	--tags-color: #{$tags-color};
	--tags-color-20: #{rgba($tags-color,0.8)};
	--tags-color-40: #{rgba($tags-color,0.6)};
	--header-links-color: #{$header-links-color};
	--title-color: #{$title-color};
	--title-color-30: #{rgba($title-color,0.7)};
	--footer-bg-color: #{$home-stripe-color};
	--category-color: #{$highlight-color};
	--home-stripe-color: #{$dark-color};
	--lightgallery-color: #{rgba($dark-color,0.1)};
	--pagefind-ui-scale: 0.7;
	--pagefind-ui-background: #{$dark-color};
	--pagefind-ui-border-radius: var(--corner-radius-small);
	--pagefind-ui-border: #{$dark-color};
	--pagefind-ui-text: #{$text-color};
	--pagefind-ui-primary: #{$highlight-color};
	--pagefind-ui-tag: #{$tags-color};
	--pagefind-ui-font: 'Montserrat',
	"Font Awesome 6 Free",
	sans-serif;
}

@media (prefers-color-scheme: dark) {
	:root {
		--theme-mode: dark;
	}

}

@media (prefers-color-scheme: light) {
	:root {
		--theme-mode: light;
	}
}

.page-link {
	--bs-pagination-active-bg: var(--background-color);
	--bs-pagination-active-border-color: var(--background-color);
	--bs-pagination-active-color: var(--text-color);
	--bs-pagination-color: var(--text-color-inverted);
	--bs-pagination-bg: var(--dark-color);
	--bs-pagination-border-color: var(--dark-color);
	--bs-pagination-hover-bg: var(--highlight-color);
	--bs-pagination-hover-border-color: var(--highlight-color);
	--bs-pagination-hover-color: var(--text-color);
	--bs-pagination-disabled-color: var(--text-color-80);
	--bs-pagination-disabled-bg: var(--background-color);
	--bs-pagination-disabled-border-color: var(--background-color);
}

html {
	margin: 0;
	padding: 0;
	height: 100%;
}

html body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: 'Montserrat', "Font Awesome 6 Free", sans-serif;
	background-color: var(--background-color);
	color: var(--text-color);
	font-size: .9375rem;

	a {
		color: var(--highlight-color);
	}
}

input,
.form-control {
	background: var(--dark-color);
	border-style: none;
	color: var(--text-color-inverted);

	&::placeholder {
		color: var(--text-color-inverted-60);
	}
}

a {}

time {
	font-weight: bold;
}

blockquote {
	border-left: 5px solid var(--text-color-20);
	padding: 0.5em 1em;
}

code {
	background-color: var(--dark-color);
	border-radius: var(--corner-radius-small);
	color: var(--text-color-inverted);
}

table {
	table-layout: auto;
	overflow: hidden;
	border-collapse: collapse;
	border-radius: var(--corner-radius-small);
	border-style: hidden;

	td, th {
		padding: 0.2em 1em;
		border: 1px solid var(--text-color-80);
	}

	thead {
		background-color: var(--dark-color);
		padding: 0.5em;
		color: var(--text-color-inverted);
	}
	
	tbody {
		
		
		tr {
			border-bottom: 1px solid var(--text-color-80);
		}
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100%;
	//min-height: 100%;
	//position: relative;
}

.pagefind-ui__result-link {
	color: var(--highlight-color) !important;
}

.portoflio-elements-container {}

/* Portfolio elements */
.portoflio-elements-grid {
	list-style: none;
	text-align: center;
	margin: 0 auto;
	padding-bottom: 2em;

	@media (min-width: 1150px) {
		width: 1150px;
	}
}

.portfolio-element {
	display: inline-block;
	padding: 7px 6px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.ytp-title-text {
	color: #000;
}

.portfolio-element-wrapper {

	cursor: pointer;
	box-shadow: 0px 0 15px 0px rgba(0, 0, 0, 0.2);
	border: 1px dashed 0px var(--dark-color-90);
	background-color: 0px var(--dark-color-90);
	border-radius: var(--corner-radius-medium);

	a {
		display: block;
	}

	&:hover {
		box-shadow: 0px 0 15px 0px var(--highlight-color-80);

		.portfolio-element-footer {
			background-color: var(--highlight-color);
			color: var(--text-color-inverted);
			transition-duration: 0.3s;
			transition-property: background-color, color;
		}

		.portfolio-element-tags .portfolio-element-tag {
			opacity: 1;
			transition: opacity 0.3s;
		}

		.portfolio-element-label {
			text-shadow: 0px 0px 1em rgba(0, 0, 0, 1);
		}
	}
}

.portfolio-element-img {
	pointer-events: none;
	width: 240px;
	height: 192px;
}

.portfolio-element-top {
	overflow: hidden;
	position: relative;
	text-align: right;
	width: 100%;
	height: 192px;
	width: 220px;
	background-size: cover;
	background-position: center;
	border-radius: var(--corner-radius-medium);

	@media (max-width: $phoneSize) {
		height: 262px;
		width: 300px;
	}
}


.portfolio-element-footer {
	/*background-color: @portfolio-element-bg;
    padding: 4px 10px 4px 10px;
    color: opacify($dark-color,20%);
    min-height: 30px;
    font-size: 14px;
    font-family: "Ubuntu";*/

	background-color: black;
	//backdrop-filter: blur(8px);
	padding: 4px 10px 4px 10px;
	color: var(--text-color-inverted);
	min-height: 1em;
	font-size: 1em;
	font-family: $fancy-font;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	text-align: center;
	overflow: hidden;

	#content {
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		position: absolute;
		background-position: bottom 0px left 0px;
		background-size: cover;
		background-blend-mode: screen;
		opacity: 0.6;
	}

	.portfolio-element-label {
		position: absolute;
		bottom: 0;
		left: 4px;
		right: 4px;
		top: 2px;
		word-wrap: normal;
	}

	.ghost {
		visibility: hidden;
	}

	@media (max-width: $phoneSize) {
		font-size: 1.2em;
		background-color: var(--dark-color-20);
	}
}

.portfolio-element-tags {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row-reverse;
	padding: 8px 10px;
	position: absolute;
	width: 80%;
	text-align: right;
	right: 0;
	gap: 0.3em;
}

.portfolio-element-tag {
	position: relative;
	background-color: var(--tags-color-40);
	color: var(--text-color-inverted);
	border-radius: 6px;
	font-size: 0.6em;
	padding: 1px 4px 1px 4px;
	text-transform: uppercase;
	outline-width: 1px;
	outline-color: var(--text-color-80);
	outline-style: solid;
	overflow: hidden;
	background-size: 220px 190px;
	background-position: center;
	background-blend-mode: multiply;

	i {
		font-size: 1.5em;
		padding-left: 1px;
		padding-right: 1px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	&:visited,
	&:active,
	&:link {
		color: var(--text-color-inverted);
		text-decoration: none;
	}

	&:hover {
		background-color: var(--highlight-color);
		color: var(--dark-color);
		background-image: none !important;
		background-blend-mode: normal;
		opacity: 1;
	}

	@media (max-width: $phoneSize) {
		opacity: 0.9;
		font-size: 0.8em;
	}
}

.portfolio-item {}

.portfolio-item-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex-grow: 1;
	column-gap: 1em;
}

.portfolio-item-tag,
.portfolio-item-tag:link,
.portfolio-item-tag:active {
	background-color: var(--tags-color);
	color: var(--text-color-inverted);
	padding: 1px 4px;
	border-radius: 4px;
	margin-right: 2px;
	text-decoration: none;

	&:hover,
	&:focus {
		background-color: var(--highlight-color);
		text-decoration: none;
		color: var(--text-color-inverted);
	}
}

.portfolio-item-img-container {
	flex-basis: 60%;
	flex-grow: 1;
	flex-shrink: 1;
	max-width: 45em;
}

.portfolio-item-description-container {
	flex-basis: 30%;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	gap: 0.5em;

	.date {
		color: var(--text-color-40);
	}

	h1 {
		margin-bottom: 0;
	}

	.keywords {
		padding-bottom: 0.5em;
		margin-bottom: 0;
		border-color: var(--text-color-80);
		border-bottom-style: dashed;
		border-bottom-width: 1px;
	}
}

.portfolio-item-img-wrapper {
	position: relative;
}

.portfolio-item-img {
	margin-bottom: 8px;
	border-radius: 6px;
	-ms-interpolation-mode: bicubic;
	background-color: var(--dark-color-90);
	border-color: var(--dark-color-90);
	border-style: dashed;
	border-width: 1px;
	overflow: hidden;
}

.portfolio-item-description {
	font-family: $fancy-font;

}

.portfolio-item-img-description {
	position: absolute;
	background-color: var(--dark-color);
	color: var(--text-color-inverted);
	bottom: 26px;
	left: -12px;
	padding: 4px 8px;
	border-radius: 6px;
	max-width: 50%;
	font-family: $fancy-font;
}

.portfolio-item-video-description {
	position: absolute;
	background-color: var(--dark-color);
	color: var(--text-color-inverted);
	top: 26px;
	left: -12px;
	padding: 4px 8px;
	border-radius: 6px;
	max-width: 50%;
	font-family: $fancy-font;
}

.send-button {
	float: right;
	border: none;
}

.contacts-page {
	//display: flex;
	//gap: 2em;
	//flex-wrap: wrap;
	//justify-content: center;
	//flex-direction: row-reverse;

	.contact-row {
		display: flex;
		flex-direction: column;
		gap: 0.1em;
		color: var(--dark-color);
		text-decoration: none;

		strong {
			color: var(--highlight-color);
			font-size: 1.5em;
			font-weight: bold
		}

		.url {
			background-color: var(--dark-color);
			border-radius: var(--corner-radius-small);
			padding: 0.5em;
			color: var(--text-color-inverted);
			text-decoration: none;
		}

		&:hover .url {
			background-color: var(--highlight-color);
		}

		.fa {
			font-size: 1em;
			margin-left: -0.7em;

			&:hover {
				color: var(--highlight-color);
				opacity: 1;
			}
		}
	}

	.contacts-section-form {
		flex-grow: 0.5;
	}

	.contacts-section {
		display: flex;
		flex-direction: column;
		gap: 1em;
		flex-grow: 0.5;

		&:first-child {
			border-right: 1px dashed var(--dark-color-90);
			padding-right: 18px;
		}
	}
}



.error-template {
	text-align: center;
}

.error-actions {
	a {
		margin: 6px;
	}
}

.button {
	background-color: var(--dark-color);
	color: var(--text-color-inverted);
	display: inline-block;
	border-radius: 4px;
	text-decoration: none;

	&:hover,
	&:focus {
		background-color: var(--highlight-color);
		text-decoration: none;
		color: var(--text-color-inverted);
	}
}

.button-primary {
	padding: 8px 12px;

	span {
		margin-right: 4px;
	}
}

.button-small {
	padding: 0.0em 0.3em;

	span {
		margin-right: 4px;
	}
}

.link-group {
	display: flex;
	align-items: center;
	gap: 0.5em;

	.button {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.icon-button {
		font-size: 2.5em;
	}
}

.category-description {
	color: opacify($dark-color, 0.5);
	font-family: $fancy-font;
	padding-bottom: 8px;
	margin-bottom: 24px;
	text-align: center;
	font-size: 18px;
	border-bottom: 1px dashed var(--dark-color-90);
}

.page-separator {
	border-bottom-color: var(--text-color-80);
	border-bottom-style: dashed;
	border-bottom-width: 1px;
	margin-bottom: 38px;
}

.portfolio .page-separator {
	border-bottom-style: none;
}

.quick-fade {
	-webkit-transition: all 0.14s ease 0s;
	transition: all 0.14s ease 0s;
}

button {
	border: none;

	&:focus {
		outline: none;
	}
}

.story {
	display: none;
}

.blog-list-header {
	display: flex;
	justify-content: right;
	margin-right: 30px;

	.sections {
		display: flex;
		gap: 0.2em;
		padding-bottom: 1em;
	}

	form {
		display: flex;
		gap: 0.3em;
	}

	input {
		height: 2em;
		border-radius: var(--corner-radius-small);
		border-width: thin;
		text-indent: 1em;
	}

	a,
	button {
		display: inline-block;
		text-decoration: none;
		color: var(--text-color-inverted);
		background-color: var(--dark-color);
		border-radius: var(--corner-radius-small);
		padding: 0.3em 0.4em 0.1em 0.4em;
		width: 2em;
		height: 2em;
		text-align: center;
	}

	.feed-section {
		display: inline-block;
		text-decoration: none;
		color: var(--text-color-inverted);
		background-color: var(--dark-color);
		border-radius: var(--corner-radius-small);
		padding: 0.3em 0.4em 0.1em 0.4em;
		width: 2em;
		height: 2em;
		text-align: center;
	}
}

.blog-post {
	color: var(--text-color);

	iframe {
		max-width: 100%;
	}

	img {
		display: block;
		max-width: 100%;
		height: auto;
	}

	a {
		color: var(--highlight-color);
	}

	.header {
		display: flex;
		margin-top: 1em;
		justify-content: center;
		padding-bottom: 1em;
		flex-wrap: wrap;
		gap: 1em;

		.section {

			display: flex;
			align-items: center;
			gap: 0.3em;

			span {
				padding: 0.2em;
			}
		}

		ul {
			padding-inline-start: 0.5em;
			display: flex;
			flex-wrap: wrap;
			gap: 0.5em;
			align-items: center;
			margin-bottom: 0;
			margin-right: 0.3em;

			li {
				display: inline;
				background-color: var(--dark-color);
				color: var(--text-color-inverted);
				border-radius: 4px;
				padding: 0.1em 0.4em;

				&:hover {
					background-color: var(--highlight-color);
				}
			}
		}

		a {
			text-decoration: none;
			color: var(--text-color-inverted);
		}
	}

	.content {
		border-bottom-color: var(--dark-color-90);
		border-bottom-style: dashed;
		border-bottom-width: thin;
		padding-bottom: 1em;
		margin-bottom: 1em;
		margin-top: 1em;

		@media (max-width: 780px) {
			padding-left: 1em;
			padding-right: 1em;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}

		video {
			max-width: 100%;
			height: auto !important;
		}
	}
}

.featured-images {
	height: 12em;
	border-radius: 4px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.post-list {
	display: flex;
	flex-direction: column;
	gap: 1em;
	list-style-type: none;
	//margin-right: 30px;

	.blog-post {

		//border-bottom-color: var(--text-color-80);
		//border-bottom-style: dashed;
		//border-bottom-width: thin;
		//padding-bottom: 2em;
		margin-bottom: 1em;

		.thumbnail {
			height: 21em;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			border-style: none;
			overflow: hidden;
			padding: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:hover {
				filter: drop-shadow(0 0 0.5em var(--highlight-color-80)) sepia(20%);
			}
		}

		.title {
			text-decoration: none;
			color: inherit;
		}

		.info {
			color: var(--text-color-40);
			font-size: 0.9em;
		}

		.read-more {
			margin-top: 0.5em;
			color: var(--text-color-inverted);
		}
	}
}

.lightgallery {
	background-color: var(--lightgallery-color);
	padding: 1em;
	border-radius: 6px;
	margin-left: auto;
	margin-right: auto;
}

.grid-item {
	margin-bottom: 8px;
	border-radius: 6px;
	background-color: black;

	&:hover {

		transition: 0.3s;

		#magnifying-glass {
			opacity: 100%;
			transition: 0.3s;
		}

		.fa-circle-play {
			opacity: 0%;
			transition: 0.3s;
		}

		img {
			opacity: 60%;
			transition: 0.3s;
		}
	}

	#magnifying-glass {
		opacity: 0%;
	}

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
		color: var(--text-color);
		font-size: 2em;
	}

	img {
		border-radius: 6px;
		//box-shadow: 0px 0 15px 0px rgba(0,0,0,0.2);
		border-style: solid;
		border-width: 3px;
		border-color: var(--home-stripe-color);
		width: 100%;
		object-fit: cover;
	}
}

.grid-item--width1 {
	width: 200px;
}

.grid-item--width2 {
	width: 400px;

	@media (max-width: $phoneSize) {
		width: 200px;
	}
}

.grid {
	margin: 0 auto;
}

.gallery {
	.description {
		padding-bottom: 1em;
		border-color: var(--dark-color-90);
		border-bottom-width: 1px;
		border-bottom-style: dashed;
		margin-bottom: 1em;
	}
}

.achievement {

	padding-top: 1em;
	padding-bottom: 1em;

	i {
		margin-top: 0.3em;
	}
}

.attribute {
	i {
		font-size: 1em;
		margin-right: 0.5em;
	}
}

caption {
	color: var(--text-color);
	font-weight: bold;
}

.table>tbody>tr>td {
	border-top-color: var(--dark-color-80);
}

.progress {
	background-image: none;
	background-color: var(--dark-color);
}

.progress-bar {
	background-color: var(--highlight-color);
	background-image: none;
	color: white;
}

.img-thumbnail {
	background-color: var(--dark-color);
	border-style: none;
}

/* extra positioning */
.thumbnail {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	background-color: var(--dark-color);
	color: var(--text-color-inverted);
	border-style: none;
	border-radius: var(--corner-radius-small);

	.caption {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: var(--text-color-inverted);

		.text-muted {
			color: var(--text-color-inverted-60);
		}
	}
}

.row.display-flex {
	display: flex;
	flex-wrap: wrap;
}

.table {
	margin-bottom: 0;
}

.about-avatar {
	margin-bottom: 1em;
}

.experience-row {
	padding-left: 0px;

	.col {
		padding-top: 0.2em;
		padding-bottom: 0.2em;
		padding-left: 0px;
	}
}

.work-link {
	width: 7em;
	height: 4em;
	border-radius: var(--corner-radius-small);
	overflow: hidden;
	position: relative;

	.open-link {
		border-radius: var(--corner-radius-small) 0 0 0;
		background-color: var(--dark-color);
		padding: 0.2em 0.4em 0.2em 0.4em;
		position: absolute;
		right: 0;
		bottom: 0;
		color: var(--text-color);
		text-align: center;
		text-anchor: center;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.work-preview {
	border-radius: var(--corner-radius-small);
	width: 96px;
	height: 96px;
}

.experience-icon {
	margin-right: 1em;
}

.work-header {}

.work-description {
	display: flex;
	flex-direction: column;
	gap: 1em;
}

.work-links {
	display: flex;
	flex-direction: row;
	gap: 0.5em;
}

.work-tags {
	display: flex;
	flex-direction: row;
	gap: 0.5em;
}

.work-tag {
	background-color: var(--dark-color);
	color: var(--text-color-inverted);
	padding: 0.2em 0.5em;
	border-radius: var(--corner-radius-small);
}

.other-experiences {
	color: var(--text-color-inverted-40);

	.experience:not(:last-child) {
		border-right-style: solid;
		border-right-color: var(--text-color-inverted-90);
		border-right-width: 2px;
		padding-right: 1em;
	}
}

.page {
	flex-grow: 1;
	margin-bottom: 3em;
}

.subtitle-container {
	margin-bottom: 12px;
}

.subtitle {
	font-size: 1.6em;
	color: var(--text-color-40);
}

.tags {

	.tag {
		display: inline-block;
		justify-content: center;
		background-color: var(--dark-color-80);
		padding-right: 0.3em;
		padding-left: 0.3em;
		padding-top: 0.1em;
		padding-bottom: 0.1em;
		border-radius: 0.4em;
		margin-bottom: 0.2em;

		&:hover {
			background-color: var(--dark-color-40);

			.name,
			.count {
				color: var(--text-color-inverted);
			}
		}

		.name {
			display: inline-block;
		}

		.count {
			display: inline-block;
			margin-left: 0.2em;
			background-color: var(--dark-color-60);
			color: var(--text-color);
			border-radius: 0.4em;
			height: 1.4em;
			width: 1.4em;
			font-weight: bold;
			font-size: 0.8em;
			text-align: center;
		}
	}

	.tag.selected {
		background-color: var(--highlight-color);

		.name,
		.count {
			color: var(--text-color-inverted);
		}
	}

	a {
		align-items: center;
		display: inline-block;
		text-decoration: none;
		color: var(--text-color-40);
	}
}

.categories-container {
	background-color: var(--home-stripe-color);

	@media (max-width: $phoneSize) {
		//display: none;
	}
}

.categories {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 2em;
	padding-top: 2em;
	margin-bottom: 4em;
	align-items: center;
	justify-content: center;

	.row .category-col:first-child {
		border-left: 0px;
	}
}

.category-wrapper {
	color: var(--text-color-inverted-60);
	padding-top: 2.5em;
	padding-bottom: 2.5em;
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 2em;

	@media (max-width: $tabletSize) {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}

	&:hover {
		background-color: var(--highlight-color);
		color: var(--text-color-inverted);
		box-shadow: 0px 0 15px 0px rgba(0, 0, 0, 0.2);

		img {
			-webkit-filter: invert(100%);
			filter: invert(100%);
		}

		i {
			color: var(--text-color-inverted);
			text-shadow: 0.05em 0.05em rgba(0, 0, 0, .2);
		}
	}
}

.category-col {
	text-align: center;
	border-left: 1px dashed rgba(0, 0, 0, 0.5);
	width: 16em;

	@media (max-width: $tabletSize) {
		width: 12em;
	}

	@media (max-width: $phoneSize) {
		width: 10em;
	}

	&:first-of-type {
		border-left: none;
	}

	a:hover,
	a:link {
		text-decoration: none;
	}
}

.category-image {
	font-size: 5em;
	color: var(--category-color);

	@media (max-width: $tabletSize) {
		font-size: 4em;
	}

	@media (max-width: $phoneSize) {
		font-size: 3em;
	}

	img {
		max-height: 86px;
		max-width: 86px;
		height: 80%;
		width: 80%;
		opacity: 0.8;
	}
}