$phoneSize: 480px;
$tabletSize: 768px;

$pWhite: #EDEBE6;
$pBlack: #1C2130;
$pRed: #D14334;
$pYellow: #DFBA69;

$background-color-dark: #232c31;
$highlight-color-dark: #99b35c;
$text-color-dark: #dbd7d2;
$title-color-dark: #7c818c;
$dark-color-dark: #1d2528;
$tags-color-dark: #1f272a;
$footer-bg-color-dark: #404B69;
$home-stripe-color: #161e20;
$category-color-dark: #37464d;

$background-color: #fafafa;
$dark-color: #1c2130;
$text-color: #2b3e50;
$highlight-color: #d3643b;
$tags-color: #1c2130;
$header-links-color: #2980b9;
$title-color: #1c2130;

$fancy-font: "Ubuntu";