.footer {
    width: 100%;
    background-color: var(--footer-bg-color);
    box-shadow: 0px -12px 38px 0px rgba(0, 0, 0, 0.08);
    border-top-style: solid;
    border-top-color: var(--background-color);
    border-top-width: 1px;
    //position: absolute;
    //bottom: 0;
    //left: 0;

    a,
    a:visited {}

    a:hover,
    .footer a:active {
        text-decoration: none;
        color: var(--text-color-inverted);
    }
}

.footer-top {
    color: var(--text-color-inverted-60);

    h4 {
        color: var(--text-color-inverted);
    }
}

.sections-wrapper {
    .section:nth-child(2) {
        border-left: 1px dashed var(--text-color-80);
        border-right: 1px dashed var(--text-color-80);
    }

    .section:nth-child(3) {
        border-right: 1px dashed var(--text-color-80);
    }
}

.footer-bottom {
    padding-top: 1em;
    padding-bottom: 1em;
    color: var(--text-color-inverted-60);
    background-color: rgba($color: black, $alpha: 0.4);
    //background-color: #0f1321;
    height: 20%;

    .contact-row {
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $phoneSize) {
            margin-top: 1em;
        }

        a {

            color: var(--text-color-inverted);

            &:hover {
                color: var(--highlight-color);
            }

            p {
                font-size: 0;

                @media (max-width: $phoneSize) {
                    font-size: 1em;
                }
            }

            i {
                padding: 1em;
                margin: 0;
                padding: 0.2em;
                font-size: 1em;

                @media (max-width: $phoneSize) {
                    font-size: 2em;
                    padding: 0.5em;
                    width: 2em;
                    height: 2em;
                }
            }
        }
    }
}