.header-bg {
	padding-top: 1.5em;
	padding-bottom: 1em;
	margin-bottom: 3em;
	flex-grow: 1;
}

.header-content {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	column-gap: 2em;
}

.header-title {
	flex-grow: 1;
	display: flex;
	font-size: 1.1em;

	a {
		display: flex;
		align-items: center;
		color: var(--text-color);
	}

	h1 {
		font-weight: bold;
	}

	i {
		font-size: 2em;
		margin-top: 0.2em;
		margin-right: 0.5em;
	}

	a:hover,
	a:link,
	i {
		text-decoration: none;
		color: var(--title-color-30);
	}

	@media (max-width: $phoneSize) {
		text-align: center;
	}
}

.header-menu {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	row-gap: 0.5em;
	text-align: right;
	align-content: center;
	text-decoration: none;
	align-items: center;
	justify-content: center;

	.separator {
		float: right;
		border-width: 1px 1px 0 0;
		border-style: solid;
		border-color: var(--dark-color-80);
		height: 2em;
		width: 0;
		margin: 0.2em;
	}

	a {
		display: flex;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 10px;
		padding-right: 10px;
		color: var(--header-links-color);

		&.current {
			color: var(--dark-color);
			color: var(--text-color-inverted);
			background: var(--highlight-color);
			border-radius: 4px;
			cursor: default;

			@media (prefers-color-scheme: dark) {
				box-shadow: 0px 0 15px 0px rgba(0, 0, 0, 0.2);
			}

		}

		&:first-child::after {
			visibility: hidden;
		}

		&:hover:not(.current) {
			color: white;
			background: var(--highlight-color);
			border-radius: 4px;

			&::after {
				visibility: hidden;
			}
		}
	}

	[data-theme-toggle] {
		margin-left: 10px;
		margin-right: 10px;
		width: 2em;
		height: 2em;
		cursor: pointer;
		justify-content: center;
		vertical-align: middle;
	}

	.dark-mode [data-theme-toggle] {
		background-color: var(--dark-color);
		color: var(--text-color);
	}
}


.header-menu a,
a:hover,
.header-menu a:link,
.header-menu a:visited {
	text-decoration: none;
}

.header-menu a:hover:not(.current)+a::after {
	visibility: hidden;
}